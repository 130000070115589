@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,300&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://thumbs.gfycat.com/CapitalEsteemedBug-size_restricted.gif")
    50% 50% no-repeat #266de1;
}


/* public profile ui css */
.Profile_Body__1YDUM{
   background-color: #393E46;
  color: white;
  margin: auto;
  padding-bottom: 1rem;
}
.Profile_User__1_vye {
   width: 200px;
   -webkit-clip-path: circle();
           clip-path: circle();
}
.Profile_PublicProfileCard__18fGA{
   color: black;
}

@media only screen and (max-width:480px){
   .Profile_Body__1YDUM{
   height: 100%;
   padding-bottom: 1rem;
   }
   .Profile_iconPickerItem__7fKZR{
      margin: auto;
   }
  
}
body{
  box-sizing: border-box;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #393E46;
  color: white;
}
.Registeration_Login__1qga6{
    margin: auto;
    margin-top: 15%;
    width: 30%;
    
}

@media only screen and (max-width:480px){
    .Registeration_Login__1qga6{
        margin-top: 40%;
      width: 100%;  
    }
}
.Main_PrivateProfile__5ziJi{
    background-color: #00ADB5;
    color: black;
    display: block;
    
}
