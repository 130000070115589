/* public profile ui css */
.Body{
   background-color: #393E46;
  color: white;
  margin: auto;
  padding-bottom: 1rem;
}
.User {
   width: 200px;
   clip-path: circle();
}
.PublicProfileCard{
   color: black;
}

@media only screen and (max-width:480px){
   .Body{
   height: 100%;
   padding-bottom: 1rem;
   }
   .iconPickerItem{
      margin: auto;
   }
  
}