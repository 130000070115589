@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,300&display=swap');
body{
  box-sizing: border-box;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #393E46;
  color: white;
}
.Login{
    margin: auto;
    margin-top: 15%;
    width: 30%;
    
}

@media only screen and (max-width:480px){
    .Login{
        margin-top: 40%;
      width: 100%;  
    }
}